import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

import langs from '@/translations';

const ES = langs.es.enlaces;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: ES.compromisos,
    name: 'compromiso',
    component: () => import(/* webpackChunkName: "compromiso" */ '../views/compromiso-gobierno.vue')
  },
  {
    path: ES.planoperativo,
    name: 'plan-operativo',
    component: () => import(/* webpackChunkName: "plan-operativo" */ '../views/plan-operativo.vue')
  },
  {
    path: `${ES.planoperativo}/:id`,
    name: 'ficha-eje-estrategico',
    component: () => import(/* webpackChunkName: "ficha-eje-estrategico" */ '../views/ficha-eje-estrategico.vue')
  },
  {
    path: ES.indicecumplimiento,
    name: 'indice-cumplimiento',
    component: () => import(/* webpackChunkName: "indice-cumplimiento" */ '../views/indice-cumplimiento.vue')
  },
  {
    path: `${ES.buscador}/:id?`,
    name: 'buscador',
    component: () => import(/* webpackChunkName: "buscador" */ '../views/buscador.vue')
  },
  {
    path: `${ES.areasaccion}/:id?`,
    name: 'areas-accion',
    component: () => import(/* webpackChunkName: "areas-accion" */ '../views/areas-accion.vue')
  },
  {
    path: `${ES.areasgobierno}/:id?`,
    name: 'areas-gobierno',
    component: () => import(/* webpackChunkName: "areas-gobierno" */ '../views/areas-gobierno.vue')
  },
  {
    path: `${ES.distritos}/:id?`,
    name: 'distritos',
    component: () => import(/* webpackChunkName: "distritos" */ '../views/distritos.vue')
  },
  {
    path: `${ES.acuerdosvilla}/:id?`,
    name: 'acuerdos-villa',
    component: () => import(/* webpackChunkName: "acuerdos" */ '../views/acuerdos-villa.vue')
  },
  {
    path: ES.cartasservicios,
    redirect: ES.listadocartasservicios
  },
  {
    path: ES.listadocartasservicios,
    name: 'listado-cartas-servicios',
    component: () => import(/* webpackChunkName: "listado-cartas-servicios" */ '../views/listado-cartas-servicios.vue')
  },
  {
    path: ES.listadocartasserviciospublico,
    name: 'listado-cartas-servicios-publico',
    component: () => import(/* webpackChunkName: "listado-cartas-servicios" */ '../views/listado-cartas-servicios.vue')
  },
  {
    path: `${ES.listadocartasservicios}/:id?/:type?`,
    name: 'ficha-cartas-servicios',
    component: () => import(/* webpackChunkName: "ficha-cartas-servicios" */ '../views/ficha-carta-servicio.vue'),
    // beforeEnter: (to, from, next) => {
    //   /** si from.name= 'ficha-indicador' entonces type='indicador' */
    //   if (from.name === 'ficha-indicador') {
    //     to.params.type = 'indicadores';
    //   }
    //   next();
    // }
  },
  {
    path: `${ES.listadocartasserviciospublico}/:id?/:type?`,
    name: 'ficha-cartas-servicios-publico',
    component: () => import(/* webpackChunkName: "ficha-cartas-servicios" */ '../views/ficha-carta-servicio.vue')
  },
  {
    path: `${ES.indicador}/:id?`,
    name: 'ficha-indicador',
    component: () => import(/* webpackChunkName: "ficha-indicador" */ '../views/ficha-indicador.vue')
  },

  {
    path: `${ES.indicadorcartas}/:id?`,
    name: 'ficha-indicador-carta',
    component: () => import(/* webpackChunkName: "ficha-indicador" */ '../views/ficha-indicador.vue')
  },

  {
    path: `${ES.indicadorpublico}/:id?`,
    name: 'ficha-indicador-publico',
    component: () => import(/* webpackChunkName: "ficha-indicador" */ '../views/ficha-indicador.vue')
  },
  {
    path: ES.avisolegal,
    name: 'aviso-legal',
    component: () => import(/* webpackChunkName: "aviso-legal" */ '../views/aviso-legal.vue')
  },
  {
    path: ES.accesibilidad,
    name: 'accesibilidad',
    component: () => import(/* webpackChunkName: "accesibilidad" */ '../views/accesibilidad.vue')
  },
  {
    path: ES.protecciondatos,
    name: 'protecciondatos',
    component: () => import(/* webpackChunkName: "proteccion-datos" */ '../views/proteccion-datos.vue')
  },
  {
    path: '/login-privado',
    name: 'login-privado',
    component: () => import(/* webpackChunkName: "login-privado" */ '../views/login-privado.vue'),
    meta: {
      login: true
    }
  },
  {
    path: '/login-privado2',
    name: 'login-privado2',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
    // component: () => import(/* webpackChunkName: "login" */ '../views/password.vue'),
    meta: {
      login: true
    }
  },
  {
    path: '/login',
    name: 'login',
    //component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),    // for login with oauth2
    component: () => import(/* webpackChunkName: "login" */ '../views/password.vue'),   // for login with password
    meta: {
      login: true
    }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import(/* webpackChunkName: "password" */ '../views/password.vue'),
    meta: {
      login: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
})


router.beforeEach((to, from, next) => {
  //console.log(window.location.href);
  let url = window.location.href;
  if (url.includes('publico')) {
    //console.log("contiene publico...");
    next();
  } else {


    /** si from.name= 'ficha-indicador' entonces type='indicador' */
    if (from.name == 'ficha-indicador' || from.name == 'ficha-indicador-publico') {
      to.params.type = 'indicadores';
      to.params.areaaccionid = from.params.areaaccionid ? from.params.areaaccionid : null;
      to.params.areagobiernoid = from.params.areagobiernoid ? from.params.areagobiernoid : null;
    }

    /** si from.name= 'ficha-indicador-carta' entonces type='indicador' */
    if (from.name == 'ficha-indicador-carta') {
      to.params.areaaccionid = from.params.areaaccionid ? from.params.areaaccionid : null;
      to.params.areagobiernoid = from.params.areagobiernoid ? from.params.areagobiernoid : null;
    }

    /** si from.name= 'ficha-carta-servicio' entonces cargo parametros */
    if (from.name == 'ficha-cartas-servicios') {
      console.log("ficha-cartas-servicios...");
      to.params.type = 'cartas';
      to.params.areaaccionid = from.params.areaaccionid ? from.params.areaaccionid : null;
      to.params.areagobiernoid = from.params.areagobiernoid ? from.params.areagobiernoid : null;
    }

    /** si from.name == areas-de-accion entonces area = params.id */
    if (from.name == 'areas-accion') {
      to.params.areaaccionid = from.params.areaaccionid;
    }

    /** si from.name == areas-de-gobierno entonces area = params.id */
    if (from.name == 'areas-gobierno') {
      to.params.areagobiernoid = from.params.areagobiernoid;
    }

    //console.log("no contiene publico...");
    if (!to.meta.login && !window.sessionStorage.getItem('pass')) {
      //next({ name: 'login' }) /** enable login functionality - habilitar funcionalidad de login */
      next()
    } else {
      next()
    }

  }

});

export default router
