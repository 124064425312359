<template>
  <section class="te-ayudamos-banner -section">

    <div class="container column-center">

      <h2 class="title -question">¿Te ayudamos?</h2>

      <h3 class="subtitle">
        No te quedes con dudas
      </h3>

      <div class="box-container row-start ais">
        <template v-for="(el, key) in data">
          <article v-if="data" class="box" :key="key">
            <h2 class="title -primary -left">{{ banners[el].titulo }}</h2>
            <p>{{ banners[el].cuerpo }}</p>

            <a target="_blank" :title="banners[el].title" :href="banners[el].link" class="show-more"></a>
            <a target="_blank" :title="banners[el].title" :href="banners[el].link" class="enlace-cover"></a>
          </article>
        </template>

      </div>




    </div>

  </section>
</template>


<script>
export default {
  name: 'banner-ayudamos',
  props: ['data'],
  data: () => ({
    banners: {
      'plan-estrategico': {
        titulo: "¿Qué es el Plan Estratégico de la ciudad de Madrid?",
        cuerpo: "Consulta el Plan Estratégico de la ciudad de Madrid 2019-2023",
        title: "Ayuntamiento de Madrid - Plan Estratégico",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Estrategia-y-planificacion/Plan-Estrategico/?vgnextfmt=default&vgnextoid=7d16efa698049710VgnVCM2000001f4a900aRCRD&vgnextchannel=486a261f46839710VgnVCM1000001d4a900aRCRD"
      },
      'que-es-pog': {
        titulo: "¿Qué es el POG?",
        cuerpo: "Conoce el Plan Operativo de Gobierno para el mandato 2019 - 2023",
        title: "Ayuntamiento de Madrid - Plan Operativo de Gobierno",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Estrategia-y-planificacion/Plan-Operativo-de-Gobierno/?vgnextfmt=default&vgnextoid=1297efa698049710VgnVCM2000001f4a900aRCRD&vgnextchannel=486a261f46839710VgnVCM1000001d4a900aRCRD"
      },
      'que-son-cartas-servicios': {
        titulo: "¿Qué son las cartas de servicios?",
        cuerpo: "Te explicamos el Sistema de cartas de servicios",
        title: "Ayuntamiento de Madrid - Cartas de servicios",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Herramientas-y-sistemas-de-Calidad/Cartas-de-Servicios/Cartas-de-servicios/?vgnextfmt=default&vgnextoid=4133f6e510149710VgnVCM2000001f4a900aRCRD&vgnextchannel=89782ca076b39710VgnVCM2000001f4a900aRCRD"
      },
      'areas-accion': {
        titulo: "¿Qué son las áreas de acción?",
        cuerpo: "Consulta las áreas de acción del Ayuntamiento de Madrid",
        title: "Ayuntamiento de Madrid - Áreas de Acción",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Observatorio-de-la-Ciudad/Areas-de-Accion/Areas-de-Accion/?vgnextfmt=default&vgnextoid=0618e6702aa65710VgnVCM1000001d4a900aRCRD&vgnextchannel=bafd59f24ac25710VgnVCM2000001f4a900aRCRD"
      },
      'distritos': {
        titulo: "¿Cuáles son los distritos de Madrid?",
        cuerpo: "Consulta los distritos de Madrid",
        title: "Ayuntamiento de Madrid - Distritos de Madrid",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Organizacion-municipal?vgnextfmt=default&vgnextchannel=2ef308a90a1e9410VgnVCM100000171f5a0aRCRD#distritos"
      },
      'areas-gobierno': {
        titulo: "¿Cuáles son las Áreas de Gobierno de la ciudad de Madrid? ",
        cuerpo: "Consulta las áreas en las que se estructura la Administración del Ayuntamiento de Madrid",
        title: "Ayuntamiento de Madrid - Áreas de Gobierno",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento?vgnextfmt=default&vgnextchannel=ce069e242ab26010VgnVCM100000dc0ca8c0RCRD"
      },
      'pleno': {
        titulo: "El Pleno",
        cuerpo: "Consulta información del funcionamiento y actividades del órgano de representación política de los ciudadanos",
        title: "Ayuntamiento de Madrid - El Pleno",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/El-Pleno?vgnextfmt=default&vgnextchannel=63f1f14a33972210VgnVCM2000000c205a0aRCRD"
      },
      'cartas-evaluan': {
        titulo: "¿Cómo se evalúan las cartas de servicios?",
        cuerpo: "Consulta como se evalúan las cartas de servicios",
        title: "Ayuntamiento de Madrid - Cartas",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Cartas-de-Servicios/Evaluacion/?vgnextfmt=default&vgnextchannel=9b026a437bf07410VgnVCM2000000c205a0aRCRD"
      },
      'competencias-areas': {
        titulo: "¿Qué competencias tiene cada Área de Gobierno?",
        cuerpo: "Consulta las competencias de las áreas en las que se estructura la administración del Ayuntamiento de Madrid",
        title: "Ayuntamiento de Madrid - Competencias Áreas de gobierno",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Organizacion-municipal/?vgnextfmt=default&vgnextchannel=2ef308a90a1e9410VgnVCM100000171f5a0aRCRD"
      },
      'como-se-clasifican': {
        titulo: "¿Cómo se clasifican las cartas de servicios?",
        cuerpo: "Consulta qué son y cómo se clasifican las cartas de servicios",
        title: "Ayuntamiento de Madrid - Clasificación Cartas de servicios",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Cartas-de-Servicios/Concepto-y-elaboracion/Que-son-las-Cartas-de-Servicios-y-como-se-clasifican-/?vgnextfmt=default&vgnextoid=2671e38ecb702210VgnVCM1000000b205a0aRCRD&vgnextchannel=048c4225faf07410VgnVCM2000000c205a0aRCRD"
      },
      'observatorio-ciudad': {
        titulo: "Observatorio de la Ciudad",
        cuerpo: "Conoce los resultados de la evaluación de la gestión municipal",
        title: "Ayuntamiento de Madrid - Observatorio de la Ciudad",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Observatorio-de-la-Ciudad/?vgnextfmt=default&vgnextchannel=ec38d941c9b25710VgnVCM2000001f4a900aRCRD"
      },
      'cartas-vigentes': {
        titulo: "¿Cuáles son las cartas de servicios actualmente vigentes?",
        cuerpo: "Accede a cada una de ellas a través de este mapa interactivo.",
        title: "Ayuntamiento de Madrid - Cartas vigentes",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Herramientas-y-sistemas-de-Calidad/Cartas-de-Servicios/Cartas-de-servicios/?vgnextfmt=default&vgnextoid=234075f32c60f710VgnVCM2000001f4a900aRCRD&vgnextchannel=89782ca076b39710VgnVCM2000001f4a900aRCRD"
      },
      'calidad': {
        titulo: "¿Quieres saber más sobre la calidad en tu Ayuntamiento?",
        cuerpo: "Conoce el Plan de Calidad del Ayuntamiento de Madrid.",
        title: "Ayuntamiento de Madrid - Plan de Calidad",
        link: "https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Plan-de-Calidad/Plan-de-Calidad/?vgnextfmt=default&vgnextoid=07d5e363dbf17710VgnVCM2000001f4a900aRCRD&vgnextchannel=f9d9261f46839710VgnVCM1000001d4a900aRCRD"
      }
    }
  }),
}
</script>