<template>
  <div class="wrapper home -section">

    <nav class="breadcrumb">
      <div class="container row-start">
        <router-link to="/">Inicio</router-link>

      </div>
    </nav>


    <section class="intro-home">
      <h2 class="title">Compromisos de Gobierno</h2>

      <div class="home-resume">
        <div class="container row-start ais">

          <article class="box -primary-line">

            <div class="stats row-between">
              <div class="stat">
                <strong v-if="homedata">
                  <count-to :endVal="homedata.ejes" :separator="'.'"></count-to>
                </strong>
                Ejes estratégicos
              </div>
              <div class="stat">
                <strong v-if="homedata">
                  <count-to :endVal="homedata.objetivos" :separator="'.'"></count-to>
                </strong>
                Objetivos estratégicos
              </div>
            </div>

            <h2 class="title -primary -left">
              ¿Cómo queremos que sea Madrid?
            </h2>

            <p>El <strong>Plan Estratégico</strong> de la ciudad de Madrid define los grandes objetivos que se pretenden
              alcanzar para hacer de Madrid el modelo de ciudad al que se aspira</p>

            <a target="_blank"
              href="https://www.madrid.es/UnidadesDescentralizadas/Calidad/Observatorio_Ciudad/02_SG_Estrategica/Ficheros/Mapa_Estrat%C3%A9gico_2019-2023.pdf"
              class="pdf" title="Descargar Mapa Estratégico">Mapa Estratégico 2019 - 2023</a>


            <router-link :to="$t('enlaces.compromisos')" class="show-more" title="Ir a Plan Estratégico"></router-link>

          </article>


          <article class="box -primary-line">

            <div class="stats row-between">
              <div class="stat">
                <strong v-if="homedata">
                  <count-to :endVal="homedata.proyectos" :separator="'.'"></count-to>
                </strong>
                Estrategias / Proyectos
              </div>
              <div class="stat">
                <strong v-if="homedata">
                  <count-to :endVal="homedata.actuaciones" :separator="'.'"></count-to>
                </strong>
                Actuaciones
              </div>
            </div>

            <h2 class="title -primary -left">
              ¿Cómo vamos a conseguirlo?
            </h2>

            <p>El <strong>Plan Operativo de Gobierno</strong> (POG) recoge las actuaciones a través de las cuales se
              operativizan los objetivos estratégicos establecidos
            </p>


            <!-- <a target="_blank"
              href="https://www.madrid.es/UnidadesDescentralizadas/Calidad/Observatorio_Ciudad/02_SG_Estrategica/Ficheros/Mapa%20y%20POG%202019-2023_marzo_1786_30%20abril.pdf"
              class="pdf" title="Descargar Mapa Estratégico y Programa Operativo de Gobierno">Mapa Estratégico y
              Programa Operativo de Gobierno 2019 - 2023, alineados con la
              Estrategia 2030 para el Desarrollo Sostenible</a>   Reemplazado el 2020-04-28-->

            <a target="_blank"
              href="https://www.madrid.es/UnidadesDescentralizadas/Calidad/Observatorio_Ciudad/02_SG_Estrategica/Ficheros/Mapa%20y%20POG%202019-2023_marzo_1786.pdf"
              class="pdf" title="Descargar Mapa Estratégico y Programa Operativo de Gobierno">Mapa Estratégico y
              Programa Operativo de Gobierno 2019 - 2023, alineados con la
              Estrategia 2030 para el Desarrollo Sostenible</a>

            <a target="_blank"
              href="https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Estrategia-y-planificacion/Plan-Operativo-de-Gobierno-para-el-mandato-2019-2023/?vgnextfmt=default&vgnextoid=1d9d539f973bf610VgnVCM2000001f4a900aRCRD&vgnextchannel=486a261f46839710VgnVCM1000001d4a900aRCRD">
              <span class="small">De las 1.786 actuaciones aprobadas algunas han sufrido modificaciones</span>
            </a>

            <router-link :to="$t('enlaces.planoperativo')" class="show-more" title="Ir a Plan Operativo de Gobierno">
            </router-link>

          </article>


          <article class="box -primary">

            <!-- <progress-bar v-if="homedata" :title="'Actuaciones terminadas'"
              :percent="homedata.actuaciones_terminadas*100/homedata.actuaciones" :color="'green'">
            </progress-bar> -->
            <progress-bar :title="'Actuaciones terminadas y en ejecución'" :percent="terminadasyenejecucion"
              :color="'green'">
            </progress-bar>

            <count-down v-if="homedata" :init="homedata.fechainicio" :end="homedata.fechafin"
              :title="'Tiempo restante del mandato'" :percent="homedata.tiempo_restante" :color="'yellow'">
            </count-down>



            <h2 class="title -white -left">
              Índice de cumplimiento
            </h2>


            <p>Informamos sobre grado de ejecución de las actuaciones</p>

            <router-link :to="$t('enlaces.indicecumplimiento')" class="show-more" title="Ir a indice de cumplimiento">
            </router-link>

          </article>

        </div>
      </div>

    </section>

    <section class="box-accesos -section">
      <div class="container">
        <h2 class="title -white">
          Te contamos cómo hacemos de Madrid el mejor sitio para vivir
        </h2>

        <div class="box-container row-start ais">
          <article class="box">
            <div class="image-container">
              <img src="/img/madrid-mejor.png" alt="Un Madrid mejor en todas las Áreas de Acción">
            </div>
            <h2 class="title -left">Un Madrid mejor en todas las áreas de acción</h2>
            <h3 class="subtitle">Conoce qué hacemos en los distintos ámbitos de actuación</h3>
            <router-link :to="$t('enlaces.areasaccion')" class="enlace-cover" title="Ir a Áreas de Acción">
            </router-link>
          </article>

          <article class="box">
            <div class="image-container">
              <img src="/img/madrid-areas.svg" alt="Un Madrid mejor impulsado por todas las Áreas municipales">
            </div>
            <h2 class="title -left">Un Madrid mejor impulsado por todas las áreas municipales</h2>
            <h3 class="subtitle">Conoce quién es el responsable</h3>
            <router-link :to="$t('enlaces.areasgobierno')" class="enlace-cover"
              title="Ir a Áreas de Gobierno, Distritos y Pleno"></router-link>
          </article>

          <article class="box">
            <div class="image-container">
              <img src="/img/madrid-distritos.svg" alt="Un Madrid mejor en todos los distritos">
            </div>
            <h2 class="title -left">Un Madrid mejor en todos los distritos</h2>
            <h3 class="subtitle">Descubre qué hacemos en cada distrito</h3>
            <router-link :to="$t('enlaces.distritos')" class="enlace-cover" title="Ir a Distritos"></router-link>
          </article>

          <article class="box">
            <div class="image-container">
              <img src="/img/madrid-calidad.svg" alt="Un Madrid comprometido con la calidad">
            </div>
            <h2 class="title -left">Un Madrid comprometido con la calidad</h2>
            <h3 class="subtitle">Madrid cuenta con cartas de servicios con las que asume compromisos de calidad</h3>
            <router-link :to="$t('enlaces.listadocartasservicios')" class="enlace-cover"
              title="Ir a cartas de servicios"></router-link>
          </article>

          <article class="box">
            <div class="image-container">
              <img src="/img/madrid-responsable.png" width="111" alt="Un Madrid responsable con los ODS">
            </div>
            <h2 class="title -left">Un Madrid con un presupuesto transparente</h2>
            <h3 class="subtitle">Conoce cómo y en qué se gasta</h3>
            <!-- <a target="_blank" /> Enlace Viejo no funciona
              href="https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Cooperacion-y-Ciudadania-Global/Agenda-2030/Estrategia-de-localizacion-de-los-ODS-en-la-ciudad-de-Madrid/?vgnextfmt=default&vgnextoid=b7b75cd724a38710VgnVCM1000001d4a900aRCRD&vgnextchannel=5347a62071048710VgnVCM1000001d4a900aRCRD"
              class="enlace-cover" title="Ir a Estrategia de localización de los ODS en la ciudad de Madrid"></a> -->
            <!-- <a target="_blank"
              href="https://internacional.madrid.es/portales/inter/es/Seguimiento-de-la-estrategia/?vgnextfmt=default&vgnextchannel=8266254544344810VgnVCM1000008a4a900aRCRD"
              class="enlace-cover" title="Ir a Estrategia de localización de los ODS en la ciudad de Madrid"></a>  reemplazado el 08/09/2023 -->

            <a target="_blank" href="https://presupuestosabiertos.madrid.es/es/" class="enlace-cover"
              title="Ir a Presupuestos Abiertos del Ayuntamiento de Madrid"></a>
          </article>

          <article class="box">
            <div class="image-container">
              <img src="/img/madrid-transparente.svg" alt="Un Madrid con un presupuesto transparente">
            </div>
            <h2 class="title -left">Un Madrid que evalua su gestión</h2>
            <h3 class="subtitle">Conoce los resultados de la
              evaluación de la gestión municipal
              en el Observatorio de la Ciudad</h3>
            <a target="_blank"
              href="https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Observatorio-de-la-Ciudad/?vgnextfmt=default&vgnextchannel=ec38d941c9b25710VgnVCM2000001f4a900aRCRD"
              class="enlace-cover" title="Ir a Observatorio de la Ciudad"></a>
          </article>
        </div>

      </div>
    </section>


    <section class="contenedor-buscador -section">
      <h2 class="title">Actuaciones e indicadores</h2>
      <h3 class="subtitle">
        Busca actuaciones e indicadores concretos
      </h3>
      <buscador></buscador>
    </section>

    <section class="cartas-servicios-banner -section">

      <div class="container column-center">


        <h2 class="title">Sistema de cartas de servicios</h2>

        <h3 class="subtitle">
          Las cartas de servicios son documentos a través de los cuales una determinada unidad del Ayuntamiento explica
          quién es y los servicios que presta, y asume compromisos de calidad concretos que se miden a través de
          indicadores.
        </h3>

        <div class="cartas-banner-container row-center ais">

          <article class="carta-banner row-end">
            <div class="foreground absolute">
              <h2 class="title -left -white -small">Conoce qué es y cómo funciona el Sistema de cartas de servicios del
                Ayuntamiento de Madrid</h2>
              <a href="https://www.madrid.es/portales/munimadrid/es/Inicio/El-Ayuntamiento/Calidad-y-Evaluacion/Herramientas-y-sistemas-de-Calidad/Cartas-de-Servicios/Cartas-de-servicios/?vgnextfmt=default&vgnextoid=4133f6e510149710VgnVCM2000001f4a900aRCRD&vgnextchannel=89782ca076b39710VgnVCM2000001f4a900aRCRD"
                class="enlace-cover" title="Consultar cartas de servicios" target="_blank"></a>
            </div>
            <div class="info center">

            </div>
          </article>



          <article class="carta-banner row-center">
            <div class="foreground">
              <h2 class="title -left -white -small">Consulta el sistema de cartas de servicios</h2>
              <router-link :to="$t('enlaces.listadocartasservicios')" class="enlace-cover"
                title="Consulta el sistema de cartas de servicios"></router-link>
            </div>
            <div class="info">



            </div>
          </article>


        </div>



      </div>

    </section>
    <!-- 
    <section class="banner-comprometidos -section">
      <div class="container row-between">
        <div class="texto">
          <h2 class="title -primary -left">Comprometidos con hacer de Madrid el mejor sitio para vivir y disfrutar</h2>
          <p>La Estrategia 2019 - 2023 se deriva del Acuerdo de Gobierno 2019 - 2023 para el Ayuntamiento de Madrid
            suscrito entre el Partido Popular y Ciudadanos - Partido para la Ciudadanía el 14 de junio de 2019, y de los
            Acuerdos de la Villa Covid-19, aprobados por el Pleno el 7 de julio de 2020.</p>
        </div>
        <div class="video">
          <video src="/img/video.mp4" controls playsinline poster="/img/poster.jpg"></video>
        </div>
      </div>
    </section> -->


    <banner-ayudamos :data="['plan-estrategico', 'que-es-pog', 'que-son-cartas-servicios']"></banner-ayudamos>

  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      homedata: 'getHomedata'
    }),
    noiniciadas() {
      return Number(this.homedata.actuaciones_futuras) + Number(this.homedata.actuaciones_planificadas) + Number(this.homedata.actuaciones_retrasadas);
    },
    terminadasyenejecucion() {
      let noiniciadas = Number(this.homedata.actuaciones_futuras) + Number(this.homedata.actuaciones_planificadas) + Number(this.homedata.actuaciones_retrasadas);
      let terminadas = Number(this.homedata.actuaciones) - Number(noiniciadas);
      let porcentaje = (terminadas * 100) / Number(this.homedata.actuaciones);

      return Number(porcentaje);
      //return Number(this.areaseleccionada.actuaciones) - Number(this.noiniciadas) 
    },
  }
}
</script>