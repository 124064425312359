export default {
	es: {
		enlaces: {
			compromisos: '/compromisos-de-gobierno/plan-estrategico',
			planoperativo: '/compromisos-de-gobierno/plan-operativo',
			indicecumplimiento: '/compromisos-de-gobierno/indice-cumplimiento',
			buscador: '/buscador-actuaciones-e-indicadores',
			areasaccion: "/areas-de-accion",
			areasgobierno: "/areas-de-gobierno-distritos-pleno",
			distritos: '/distritos',
			cartasservicios: '/sistema-de-cartas-de-servicios',
			acuerdosvilla: '/acuerdos-villa',
			listadocartasservicios: '/sistema-de-cartas-de-servicios/cartas',
			listadocartasserviciospublico: '/sistema-de-cartas-de-servicios/cartaspublico',
			accesibilidad: '/accesibilidad',
			avisolegal: "/aviso-legal",
			protecciondatos: '/proteccion-datos',
			indicador: '/indicador',
			indicadorpublico: '/indicadorpublico',
			indicadorcartas: '/indicadorcartas',
		}
	},
	en: {
	}
}
