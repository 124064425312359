<template>
    <div :class="['countdown-container row-center', ((primary) ? '-primary' : '')]">
        <h3 v-html="title" :title="`${$options.filters.parsedate(init)} - ${$options.filters.parsedate(end)}`"></h3>
        <div class="circle-container">
            <div class="countdown">
                <count-to :endVal="percent" :suffix="'%'" :duration="3000"></count-to>
            </div>
            <svg class="countdown-circle" viewBox="0 0 100 100" width="100" height="100"
                :style="`stroke-dasharray: ${roundDraw}, 999;`">
                <circle cx="50" cy="50" r="45"></circle>
            </svg>
        </div>
    </div>
</template>

<script>

export default {
    name: 'countdown',
    props: ['title', 'percent', 'color', 'primary', 'end', 'init'],
    mounted() {
        this.setData();
    },
    data: () => ({
        roundDraw: ''
    }),
    methods: {
        setData() {
            let roundRadius = 45;
            let roundCircum = 2 * roundRadius * Math.PI;
            this.roundDraw = this.percent * roundCircum / 100
        }
    },
    watch: {
        'percent'() {
            this.setData();
        }
    },
    filters: {
        parsedate(value) {

            if (value) {
                if (value.includes('/')) {
                    return value.split('/').pop();
                }
            } else {
                return value;
            }

            // return value.split('/').pop()

        }
    }
}
</script>