import Vue from 'vue'
import Vuex from 'vuex'

import datos from './datos'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'es'
  },
  modules: {
    datos
  }
})
