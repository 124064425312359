<template>
  <div id="app">
    <app-header></app-header>
    <router-view class="margin-menu" />
    <app-footer></app-footer>
  </div>
</template>


<script>

import header from '@/components/header';
import footer from '@/components/footer';

import { mapActions } from 'vuex'

export default {
  name: 'app',
  mounted() {
    this.requestHomeData();
    this.requestDominios();
  },
  methods: {
    ...mapActions(['requestHomeData', 'requestDominios'])
  },
  components: {
    'app-header': header,
    'app-footer': footer,
  }
}
</script>

<style lang="scss">
@import '@/assets/css/colors.scss';
@import '@/assets/css/style.scss';
@import '@/assets/css/responsive.scss';
@import url('https://unpkg.com/vue-multiselect@2.1.6/dist/vue-multiselect.min.css');
</style>