<template>
    <article class="objetivo" :open="open" :canopen="proyectos(data.id).length != 0">
        <h3 @click="show">{{ data.nombre }}</h3>
        <div class="contenedor-estrategias" v-if="open && proyectos(data.id).length != 0">
            <h4>Estrategias/proyectos del objetivo</h4>
            <estrategia v-for="(estrategia, key) in proyectos(data.id)" :key="key" :estrategia="estrategia"></estrategia>
        </div>
    </article>
</template>


<script>

import { mapGetters } from 'vuex'

export default {
    name: 'objetivo',
    props: ['data'],
    data: () => ({
        open: false
    }),
    methods: {
        show() {
            if (this.proyectos(this.data.id).length != 0) {
                this.open = !this.open;
            }
        }
    },
    computed: {
        ...mapGetters({
            proyectos: 'getProyectosByObjetivo'
        })
    }
}
</script>