<template>
    <div class="estrategia" :open="open">
        <h4 @click="open = !open">{{ estrategia.nombre }}</h4>
        <div class="contenedor-tabla-estrategia" v-if="open">
            <resultados :type="'actuaciones'" :data="actuaciones(estrategia.id)" :cansearch="true"></resultados>
        </div>
    </div>
</template>



<script>

import resultados from '@/components/parts/resultados-busqueda'

import { mapGetters } from 'vuex';

export default {
    name: 'estrategia',
    props: ['estrategia'],
    data: () => ({
        open: false
    }),
    computed: {
        ...mapGetters({
            actuaciones: 'getActuacionesByProyecto'
        }),
    },
    components: {
        'resultados': resultados
    }
}
</script>