<template>
    <div class="contenedor-grafica-aavv">

        <div id="chartdivaavv"></div>

        <article class="stat -small">
            <strong>
                <count-to :endVal="total" separator='.'></count-to>
            </strong>
            <span>{{ label }}</span>
        </article>

    </div>
</template>


<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'grafica-percent-aavv',
    props: ['total', 'label', 'terminadas', 'ejecucion', 'planificadas'],
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.setChart();
    },
    data: () => ({
        root: '',
        series: ''
    }),
    methods: {
        setChart() {

            // if (this.$route.name == 'indice-cumplimiento') {
            //     colornoiniciada = '#f56f4f';
            // } else {
            //     colornoiniciada = '#67b7dc';
            // }


            this.root = am5.Root.new("chartdivaavv");

            var chart = this.root.container.children.push(
                am5percent.PieChart.new(this.root, {
                    innerRadius: am5.percent(70),
                    width: am5.percent(100),
                    height: am5.percent(100),
                    position: "relative",
                    showTooltipOn: 'always',
                    tooltipPosition: 'pointer'
                }),
            );


            this.series = chart.series.push(
                am5percent.PieSeries.new(this.root, {
                    name: "Series",
                    categoryField: "country",
                    valueField: "sales",
                    alignLabels: false
                })
            );

            /** Si la serie 1 = 0 entonces le cambio el color a 0x6794dc */
            let porcentajenoiniciada = Math.round(((this.total - this.terminadas - this.ejecucion) / this.total) * 100);

            let color2;
            if (porcentajenoiniciada == 0) {
                color2 = am5.color(0x6794dc);
            } else {
                color2 = am5.color(0xff0000);
            }

            // Custosm colors
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/pie-series/
            this.series.get("colors").set("colors", [
                am5.color(0x6794dc),
                //am5.color(0x6771dc), // Oculta por unificar terminadas y en ejecución
                color2,
                //am5.color(0x86a873),
                //am5.color(0xbb9f06)
            ]);

            this.series.labels.template.setAll({
                textType: "circular",
                templateField: "dummyLabelSettings",
                fillOpacity: 0,
                fontSize: '0px'
            });

            // this.series.ticks.template.set("forceHidden", true);


            var myTheme = am5.Theme.new(this.root);

            myTheme.rule("pie").setAll({
                fill: am5.color(0xFF0000),
                fontSize: "1.5em"
            });

            myTheme.rule("Label").set("fontSize", "0.8em");


            this.root.setThemes([
                am5themes_Animated.new(this.root),
                myTheme
            ]);

            this.series.labels.template.setAll({
                maxWidth: 150,
                oversizedBehavior: "wrap" // to truncate labels, use "truncate"
            });

            this.series.slices.template.set('tooltipText', '{category}: {value}%');
            this.series.labels.template.set('text', '{category}: {value}%');


            this.series.data.setAll([
                {
                    country: "Terminados y en ejecución",
                    //sales:this.terminadas + this.ejecucion
                    sales: Math.round(((this.terminadas + this.ejecucion) / this.total) * 100)
                },
                // {
                //     country: "En ejecución",
                //     sales: this.ejecucion,
                // },
                {
                    country: "No iniciados",
                    //sales: this.total - this.terminadas - this.ejecucion,
                    sales: Math.round(((this.total - this.terminadas - this.ejecucion) / this.total) * 100),
                    fill: am5.color(0x6771dc)
                },
            ]);




        },
        updateChartDOM() {
            /** Elimino el div chartdivaavv y lo vuelvo a generar */
            let chartdivaavv = document.getElementById('chartdivaavv');
            chartdivaavv.remove();
            let div = document.createElement('div');
            div.setAttribute('id', 'chartdivaavv');
            document.querySelector('.contenedor-grafica-aavv').appendChild(div);
            this.setChart();
        },
        myEventHandler() {
            //console.log("event", e);
            this.updateChartDOM();
        }

    },
    watch: {
        'total'() {

            // console.log('total', this.total);
            // console.log('terminadas', this.terminadas);
            // console.log('ejecucion', this.ejecucion);
            // console.log('planificadas', this.planificadas);


            this.series.data.setIndex(0, {
                country: "Terminados y en ejecución",
                //sales: this.terminadas + this.ejecucion,
                sales: Math.round(((this.terminadas + this.ejecucion) / this.total) * 100),
                //stroke: am5.color(0x6794dc)
            });

            // this.series.data.setIndex(1, {
            //     country: "En ejecución",
            //     sales: this.ejecucion,
            // });


            this.series.data.setIndex(1, {
                country: "No iniciados",
                //sales: this.total - this.terminadas - this.ejecucion,
                sales: Math.round(((this.total - this.terminadas - this.ejecucion) / this.total) * 100),
                //stroke: am5.color(0x6794dc)
            });

            /** Si la serie 1 = 0 entonces le cambio el color a 0x6794dc */
            let porcentajenoiniciada = Math.round(((this.total - this.terminadas - this.ejecucion) / this.total) * 100);

            let color2;
            if (porcentajenoiniciada == 0) {
                color2 = am5.color(0x6794dc);
            } else {
                color2 = am5.color(0xff0000);
            }


            this.series.get("colors").set("colors", [
                am5.color(0x6794dc),
                color2
            ]);

            /** Refresco la serie de nuevo */
            this.series.data.setAll([
                {
                    country: "Terminados y en ejecución",
                    //sales:this.terminadas + this.ejecucion
                    sales: Math.round(((this.terminadas + this.ejecucion) / this.total) * 100)
                },
                {
                    country: "No iniciados",
                    //sales: this.total - this.terminadas - this.ejecucion,
                    sales: Math.round(((this.total - this.terminadas - this.ejecucion) / this.total) * 100),
                },
            ]);

        }
    }
}


</script>