<template>
    <div :class="['progress-bar-container', ((primary) ? '-primary' : ''), ((xs) ? '-xs' : '')]">
        <h3 v-html="title"></h3>
        <div class="progress-bar-wrapper">
            <div :class="['progressbar', `-${color}`]">
                <span class="fill" :style="`width:${percent}%;`"></span>
            </div>
            <span :class="['percent', `-${color}`]" :style="`left:${percent}%`">{{ percent | number }}%</span>
        </div>
    </div>
</template>


<script>
export default {
    name: 'progress-bar',
    props: ['title', 'percent', 'color', 'primary', 'xs'],
    filters: {
        number(value) {
            if (value == 100) {
                return String(value).substr(0, 3)
            } else {
                return String(value).substr(0, 2)
            }

        }
    }
}
</script>