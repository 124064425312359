<template>
    <section class="buscador">
        <div class="container">


            <div class="filtro">
                <div class="categories row-center">
                    <a @click="filter.type = 'actuaciones'" :active="filter.type == 'actuaciones'">Actuaciones</a>
                    <a @click="filter.type = 'indicadores'" :active="filter.type == 'indicadores'">Indicadores</a>
                </div>

                <div class="inputs row-start" v-if="!!filter">

                    <div :class="['contenedor-input', (filter.type == 'actuaciones') ? '-middle' : '-full']">
                        <input type="text" placeholder="Buscar..." v-model="filter.texto" @keyup.enter="$emit('search')">
                    </div>


                    <div class="contenedor-input -middle -m0" v-if="filter.type == 'actuaciones'">

                        <multiselect v-model="filter.lists.status" :options="dominios.fakecumplimiento" placeholder="Estado"
                            label="nombre" :multiple="true" :closeOnSelect="false" :tagPosition="'bottom'"
                            :showLabels="false" :showNoResults="false" track-by="nombre"></multiselect>

                    </div>


                    <div class="contenedor-input" v-if="filter.type == 'indicadores'">

                        <multiselect class="multiselect-ancho" v-model="filter.lists.tipodeindicadores"
                            :options="tipodeindicadores" placeholder="Tipo de indicador" label="nombre" :multiple="true"
                            :closeOnSelect="false" :tagPosition="'bottom'" :showLabels="false" :showNoResults="false"
                            track-by="nombre" group-values="grupo" group-label="tipogrupo" :group-select="true">
                        </multiselect>
                    </div>

                    <div class="contenedor-input" v-if="dominios.ejes">

                        <multiselect v-model="filter.lists.ejes" :options="dominios.ejes" placeholder="Eje estratégico"
                            label="nombre" :multiple="true" :closeOnSelect="false" :tagPosition="'bottom'"
                            :showLabels="false" :showNoResults="false" track-by="nombre"></multiselect>

                    </div>

                    <div class="contenedor-input" v-if="dominios.areas_accion">

                        <multiselect v-model="filter.lists.areas_accion" :options="dominios.areas_accion"
                            placeholder="Áreas de Acción" label="nombre" :multiple="true" :closeOnSelect="false"
                            :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                        </multiselect>

                    </div>

                    <div :class="['contenedor-input', (filter.type == 'indicadores') ? '-m0' : '']"
                        v-if="dominios.areas_gobierno">

                        <multiselect v-model="filter.lists.areas_gobierno" :options="dominios.areas_gobierno"
                            placeholder="Áreas de Gobierno, Distritos..." label="nombre" :multiple="true"
                            :closeOnSelect="false" :tagPosition="'bottom'" :showLabels="false" :showNoResults="false"
                            track-by="nombre">
                        </multiselect>

                    </div>

                    <div class="contenedor-input -m0" v-if="dominios.distritos && filter.type == 'actuaciones'">

                        <multiselect v-model="filter.lists.distritos" :options="dominios.distritos"
                            placeholder="Ámbito territorial" label="nombre" :multiple="true" :closeOnSelect="false"
                            :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                        </multiselect>

                    </div>



                    <div class="tags row-start">

                        <template v-for="(el, key) in filter.lists">
                            <span class="tag" v-for="(data, tagkey) in el" :key="tagkey + key">
                                {{ data.nombre }}
                                <a @click="remove(key, data)">✕</a>
                            </span>
                        </template>
                        <span class="tag" v-if="filter.texto">
                            {{ filter.texto }}
                            <a @click="filter.texto = ''">✕</a>
                        </span>


                        <a @click="reset" class="erase" v-if="showerase">Borrar filtros</a>

                    </div>

                    <div class="contenedor-botones">
                        <a class="btn -search" @click="setBusqueda">Buscar</a>
                    </div>

                </div>


            </div>

        </div>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import router from '@/router';

export default {
    name: "buscador",
    mounted() {
        if (window.sessionStorage.getItem('buscador')) {
            this.filter = JSON.parse(window.sessionStorage.getItem('buscador'));
            window.sessionStorage.removeItem('buscador');
            this.$emit('input', this.filter);
        } else {
            this.$emit('input', this.filter);
        }

        if (this.$route.params.id) {
            this.filter.type = this.$route.params.id;
        }

    },
    data: () => ({
        filter: {
            lists: {
                ejes: [],
                areas_accion: [],
                areas_gobierno: [],
                distritos: [],
                status: [],
                tipodeindicadores: []
            },
            texto: '',
            type: "actuaciones",
            //tipoindicador: ''
        },
        tipodeindicadores: [
            {
                tipogrupo: "Indicador Plan Estratégico",
                grupo: [
                    { id: "estrategico", nombre: "Indicador del Plan Estratégico/Estratégico" },
                    { id: "accion", nombre: "Indicador del Plan Estratégico/De acción" },
                    { id: "sectorial", nombre: "Indicador del Plan Estratégico/Sectorial" }
                ]
            },
            {
                tipogrupo: "Indicador Plan Operativo",
                grupo: [
                    { id: "Pogevaluacion", nombre: "Indicador del Plan Operativo de Gobierno de evaluación" },
                    { id: "Poginformativo", nombre: "Indicador del Plan Operativo de Gobierno informativo" }
                ]
            },
            {
                tipogrupo: "Indicador Cartas de Servicios",
                grupo: [
                    { id: "cartainformativo", nombre: "Indicador de Cartas de Servicios informativos" },
                    { id: "cartavalorativo", nombre: "Indicador de Cartas de Servicios de cumplimiento" }
                ]
            }


            // {
            //     "id": "Pe",
            //     "nombre": "Indicador Plan Estratégico",
            // },
            // {
            //     "id": "estrategico",
            //     "nombre": "Indicador Plan Estratégico Estratégico"
            // },
            // {
            //     "id": "accion",
            //     "nombre": "Indicador Plan Estratégico de Acción"
            // },
            // {
            //     "id": "Pog",
            //     "nombre": "Indicador Plan Operativo"
            // },
            // {
            //     "id": "Pogevaluacion",
            //     "nombre": "Indicador Plan Operativo Evaluación"
            // },
            // {
            //     "id": "Poginformativo",
            //     "nombre": "Indicador Plan Operativo Informativo"
            // },
            // {
            //     "id": "carta",
            //     "nombre": "Indicador Cartas de Servicios"
            // },
            // {
            //     "id": "cartainformativo",
            //     "nombre": "Indicador Cartas de Servicios Informativo"
            // },
            // {
            //     "id": "cartavalorativo",
            //     "nombre": "Indicador Cartas de Servicios Valorativo"
            // },

        ]
    }),
    methods: {
        remove(where, value) {
            this.filter.lists[where] = this.filter.lists[where].filter(e => e.id != value.id);
        },
        setBusqueda() {
            if (this.tobuscador) {
                this.setToBuscador()
            } else {
                this.$emit('search')
            }
        },
        setToBuscador() {
            window.sessionStorage.setItem('buscador', JSON.stringify(this.filter));
            router.push({ name: 'buscador' })
        },
        reset() {
            this.filter.lists = {
                ejes: [],
                areas_accion: [],
                areas_gobierno: [],
                distritos: [],
                status: [],
                tipodeindicadores: []
            };
            this.filter.texto = '';
            //this.filter.tipoindicador = '';

            setTimeout(() => {
                this.setBusqueda();
            })
        }
    },
    computed: {
        ...mapGetters({
            dominios: 'getDominios'
        }),
        tobuscador() {
            let routes = ['Home'];
            return (routes.some(e => e == this.$route.name));
        },
        showerase() {

            let show = false;
            (this.filter.texto) ? show = true : null;

            for (let element in this.filter.lists) {
                (this.filter.lists[element].length != 0) ? show = true : null;
            }

            return show;
        }
    },
    watch: {
        'filter': {
            deep: true,
            handler() {
                this.$emit('input', this.filter);
            }
        },
        'filter.type'() {
            this.filter.lists = {
                ejes: [],
                areas_accion: [],
                areas_gobierno: [],
                distritos: [],
                status: [],
                tipodeindicadores: []
            };
            this.filter.texto = '';
            //this.filter.tipoindicador = '';

            if (router.currentRoute.name == 'buscador') {
                router.replace({
                    params: {
                        id: this.filter.type
                    }
                })
            }

        }
    }

}
</script>